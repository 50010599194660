<template>
  <div class="card">
    <div class="card-header border-0 pt-6">
      <div class="card-title">
        <div class="d-flex align-items-center position-relative my-1">
          <input
            type="text"
            v-model="searchParams.search"
            v-on:keyup.enter="handleSearch"
            class="form-control form-control-solid w-250px me-2"
            :placeholder="orderProductStatusConstant.SEARCH_TITLE"
          />
        </div>
        <el-tooltip class="box-item" effect="dark" :content="generalConstants.SEARCH" placement="top">
          <button type="button" @click.prevent="handleSearch" class="btn btn-icon btn-light btn-sm me-2"><i class="bi bi-search"></i></button>
        </el-tooltip>
        <el-tooltip class="box-item" effect="dark" :content="generalConstants.RESET" placement="top">
          <button type="button" @click.prevent="resetSearch" class="btn btn-icon btn-light btn-sm"><i class="bi bi-arrow-counterclockwise"></i></button>
        </el-tooltip>
      </div>
      <div class="card-toolbar">
        <button type="button" class="btn btn-primary" v-if="permission.isAddAllowed" @click="$router.push({ name: 'add-order-product-status' })"><i class="bi bi-plus-circle-fill fs-4"></i>
          {{ generalConstants.ADD }}
        </button>
      </div>
    </div>
    <div class="card-body pt-0">
      <Datatable
        :table-data="tableData"
        :table-header="tableHeader"
        :rows-per-page="searchParams.recordsPerPage"
        :empty-table-text="noDataFound"
        :loading="loading"
        :current-page="1"
        :enable-items-per-page-dropdown="true"
        :total="total"
        ref="updateProps"
        @current-change="handleCurrentChange"
        @items-per-page-change="handleSizeChange"
      >
        <template v-slot:cell-id="{ row: data }">
          {{ data }}
        </template>
        <template v-slot:cell-badge="{ row: data }">
          <span :class="'badge badge-light-'+data.badge">{{data.title}}</span>
          <span v-if="data.is_default == true"><b> (Default) </b></span>
          &nbsp;
          <i class="fas fa-ban text-danger ml-1" v-if="data.type == 'cancelled'" :id="'danger_'+tableData.findIndex((i) => i.id === data.id)"></i>
          <i class="fas fa-truck text-primary ml-1" v-if="data.type == 'shipped'" :id="'primary_'+tableData.findIndex((i) => i.id === data.id)"></i>
          <i class="fas fa-check-circle text-success ml-1" v-if="data.type == 'completed'" :id="'success_'+tableData.findIndex((i) => i.id === data.id)"></i>
        </template>
        <template v-slot:cell-internal_product_status="{ row: data }">
          <span :class="'badge badge-'+data.customer_status_badge" v-if="data.internal_product_status == 1 ">{{data.customer_status_name}}</span>
            <span v-else>-</span>
        </template>
        <template v-slot:cell-job_board_visibility="{ row: data }">
          <el-switch
            @change="updateStatus($event, 'job_board_visibility', data.id)"
            v-model="data.job_board_visibility"
            active-color="#13ce66"
            inactive-color="#ff4949"
          />
        </template>
        <template v-slot:cell-is_default="{ row: data }">
          <el-switch
            @change="updateStatus($event, 'is_default', data.id, tableData.findIndex((i) => i.id === data.id))"
            :disabled="data.is_default == true"
            v-model="data.is_default"
            active-color="#13ce66"
            inactive-color="#ff4949"
          />
        </template>
        <template v-slot:cell-sort_order="{ row: data }">
          <el-input-number
            v-model="data.sort_order"
            controls-position="right"
            @change="updateStatus($event, 'sort_order', data.id)"
          />
        </template>
        <template v-slot:cell-status="{ row: data }">
          <el-switch
            v-model="data.status"
            :disabled="data.is_default == true"
            @change="updateStatus($event, 'status', data.id)"
            active-color="#13ce66"
            inactive-color="#ff4949"
          />
        </template>
        <template v-slot:cell-actions="{ row: data }">
          <div v-if="permission.isEditAllowed || permission.isDeleteAllowed">
            <el-tooltip class="box-item" effect="dark" v-if="permission.isEditAllowed" :content="generalConstants.EDIT" placement="top">
              <button type="button" @click.prevent="$router.push({ name: 'edit-order-product-status', params: { id: data.id } })" class="btn btn-icon btn-light btn-sm me-2">
                <i class="bi bi-pencil-square"></i>
              </button>
            </el-tooltip>
            <el-tooltip class="box-item" effect="dark" v-if="permission.isDeleteAllowed && data.is_default == false" :content="generalConstants.DELETE" placement="top">
              <button type="button" @click.prevent="remove(data.id, tableData.findIndex((i) => i.id === data.id))" class="btn btn-icon btn-light btn-sm">
                <i class="bi bi-trash text-danger"></i>
              </button>
            </el-tooltip>
          </div>
          <div v-else>
            -
          </div>
        </template>
      </Datatable>
    </div>
  </div>
</template>

<script>
import { reactive, ref, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { notificationFire } from "@/composable/notification.js";
import { formatText } from "@/core/helpers/common";
import { useRoute, useRouter } from "vue-router";
import { ElMessageBox } from 'element-plus'
import globalConstant from "@/core/data/globalConstant.js";
import { loggedInUserRoles, loggedInUserPermissions } from "@/composable/get-roles.js";
import { Actions } from "@/store/enums/RememberSearchEnums";
import { useStore } from "vuex";

export default {
  name: "product-options",
  components: {
    Datatable,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const updateProps = ref(null);
    const search = ref("");
    const pageSize = ref(10);
    const total = ref(0);
    const tableData = ref([]);
    const currentpage = ref(1);
    const loading = ref(false);
    const generalConstants = globalConstant.general;
    const noDataFound = ref(generalConstants.LOADING);
    const orderProductStatusConstant = globalConstant.orderProductStatus;
    const moduleName = ref((route.meta.module) ? route.meta.module : '');
    const searchParams = reactive({
      search: store.getters.getSearch(moduleName.value),
      recordsPerPage: store.getters.getPerPage(moduleName.value),
      pageNub: store.getters.getPageNumber(moduleName.value),
    });
    const permission = reactive({
      isAddAllowed: false,
      isEditAllowed: false,
      isDeleteAllowed: false,
    })
    const tableHeader = ref([
      {
        name: generalConstants.SRNO,
        key: "index",
        sortable: false,
        width: "w-10px",
      },
      {
        name: generalConstants.TITLE,
        key: "badge",
        sortable: true,
        width: "w-200px",
      },
      {
        name: orderProductStatusConstant.CUSTOMER_STATUS,
        key: "internal_product_status",
        width: "w-200px",
        sortable: false,
      },
      {
        name: orderProductStatusConstant.USE_ON_JOB_BOARD,
        key: "job_board_visibility",
        width: "w-200px",
        sortable: false,
      },
      {
        name: generalConstants.IS_DEFAULT,
        key: "is_default",
        width: "w-200px",
        sortable: false,
      },
      {
        name: generalConstants.SORTORDER,
        key: "sort_order",
        width: "w-200px",
        sortable: false,
      },
      {
        name: generalConstants.STATUS,
        key: "status",
        width: "w-200px",
        sortable: false,
      },
      {
        name: generalConstants.ACTIONS,
        key: "actions",
        width: "w-200px",
        sortable: false,
      },
    ]);

    //Change User On Board, is default, sort order & status in order product status table
    const updateStatus = (status,field ,id, index='') => {
      if(field == 'is_default'){
        tableData.value.forEach((obj) => {        
          obj["is_default"] = false;
        });
        tableData.value[index]["is_default"] = true;
        tableData.value[index]["status"] = true;
      }
      ApiService.post("orderproductstatus/common-status-update", {
        status: status,
        field: field,
        id: id,
      })
        .then(({ data }) => {
          notificationFire(data.message, "success");
        })
        .catch((error) => {
          let response = error.response;
          if (response) {
            let message = response.data.message;
            notificationFire(message, "error");
          }
        });
    };

    //Get all Order Product Status
    const getOrderProductStatus = async () => {
      loading.value = true;
      await ApiService.query("orderproductstatus", { params: searchParams })
        .then(({ data }) => {
          if (data.data.data.length) {
            tableData.value = [];
            data.data.data.map(function (element, index, array) {
              element.status = element.status == 1 ? true : false;
              element.job_board_visibility = element.job_board_visibility == 1 ? true : false;
              element.is_default = element.is_default == 1 ? true : false;
            });
            tableData.value.splice(0, tableData.value.length, ...data.data.data)
            total.value = data.data.total;
            updateProps.value.updateProps(total.value, searchParams.recordsPerPage, searchParams.pageNub);
          } else {
            tableData.value = [];
            updateProps.value.updateProps(total.value, searchParams.recordsPerPage, searchParams.pageNub, generalConstants.NO_DATA_FOUND);
          }
          setCurrentPageBreadcrumbs(formatText(route.name) + " (" + total.value + ")", [{ name: formatText(route.name), active: true }]);
          loading.value = false;
        })
        .catch((error) => {
            let response = error.response;
            if (response) {
              let message = response.data.message;
              notificationFire(message, "error");
            }
            tableData.value = [];
            loading.value = false;
          });
    };

    //Delete Order Product Status
    const remove = async (id, index) => {
      ElMessageBox.confirm(
        generalConstants.DELETE_MESSAGE,
        generalConstants.ALERT_WARNING,
        {
          confirmButtonText: generalConstants.ALERT_CONFIMATION,
          cancelButtonText: generalConstants.ALERT_CANCEL,
          type: 'warning',
          center: true
        }
      )
      .then(() => {
        ApiService.delete("orderproductstatus/"+id)
        .then((response) => {
          let status = response.status;
          let message = response.data.message;
          if (status == 200) {
            notificationFire(message, 'success');
            tableData.value.splice(index, 1);
            total.value = total.value > 0  ? total.value - 1 : 0;
            setCurrentPageBreadcrumbs(formatText(route.name) + " (" + total.value + ")", [{ name: formatText(route.name), active: true }]);
          }
        })
        .catch((error) => {
          let message = error.message;
          notificationFire(message, 'error');
        });
      })
    };

    //For Pagination Size Change
    const handleSizeChange = (number) => {
      if(total.value > 10){
        store.dispatch(Actions.PER_PAGE, {'module':moduleName.value,'value':number});
        searchParams.recordsPerPage = store.getters.getPerPage(moduleName.value);
        handleCurrentChange(1);
      }
    };

    //For Pagination Number Change
    const handleCurrentChange = (number) => {
      store.dispatch(Actions.PAGE_NUMBER, {'module':moduleName.value,'value':number});
      searchParams.pageNub = store.getters.getPageNumber(moduleName.value);
      getOrderProductStatus();
    };

    //For Search Box handle event
    const handleSearch = () => {
        store.dispatch(Actions.SEARCH, {'module':moduleName.value,'value':searchParams.search});
        handleCurrentChange(1);
    };

    //Reset search value
    const resetSearch = () => {
      if(searchParams.search.length > 0){
        searchParams.search = '';
        store.dispatch(Actions.SEARCH, {'module':moduleName.value,'value':searchParams.search});
        getOrderProductStatus();
      }
    }

    onMounted(async () => {
      setCurrentPageBreadcrumbs(formatText(route.name), [{ name: formatText(route.name), active: true }]);
      let role = loggedInUserRoles();
      let per = loggedInUserPermissions();
      if (per.includes("createOrderProductStatus") || role == "Super Admin")
        permission.isAddAllowed = true;
      if (per.includes("changeOrderProductStatus") || role == "Super Admin")
        permission.isEditAllowed = true;
      if (per.includes("deleteOrderProductStatus") || role == "Super Admin")
        permission.isDeleteAllowed = true;
      await getOrderProductStatus();
    })

    return {
      tableData,
      search,
      loading,
      getOrderProductStatus,
      total,
      currentpage,
      pageSize,
      handleSizeChange,
      handleCurrentChange,
      handleSearch,
      resetSearch,
      remove,
      searchParams,
      tableHeader,
      generalConstants,
      updateProps,
      noDataFound,
      permission,
      updateStatus,
      orderProductStatusConstant
    };
  },
};
</script>
<style lang="scss">
.btn.btn-icon.btn-sm,
.btn-group-sm > .btn.btn-icon {
  height: calc(2.1em + 1.1rem + 2px);
  width: calc(2.1em + 1.1rem + 2px);
}
</style>
